import React from 'react';
import ReactDOM from 'react-dom';
import Favicon from 'react-favicon'
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import App from './App.js';

ReactDOM.render(
  <div>
  <BrowserRouter>
      <Switch>
          <Route exact path='/' component={App} />
      </Switch>
      <Favicon url='http://aliciamersy.com/favicon.png' />
  </BrowserRouter>
  </div>,
  document.getElementById('root')
);
